import { lazy, Suspense } from 'react'

import MainRoutes from 'Routes'
import SuspenseRouter from 'SuspenseRouter'

const Header = lazy(() => import('./components/Header'))
const Footer = lazy(() => import('./components/Footer'))

function App() {
  return (
    <SuspenseRouter window={window}>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <MainRoutes />
        <Footer />
      </Suspense>
    </SuspenseRouter>
  )
}

export default App
