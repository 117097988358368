import React from 'react'

import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

import 'theme/styles.css'

import { useResize } from 'hooks'
import { Providers } from 'Providers'

import App from './App'
import reportWebVitals from './reportWebVitals'

const GlobalHooks = () => {
  // Global hooks that needed to call app's first rendering such as web3 gas price, wallet eager connection, etc
  useResize()

  return null
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Providers>
      <GlobalHooks />
      <ToastContainer
        className="toast-container"
        toastClassName="toast"
        bodyClassName="body"
        progressClassName="progress"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'colored'}
      />
      <App />
    </Providers>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
