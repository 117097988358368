import { lazy } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { useGetCurrentURLPath } from 'hooks'

const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const WaterCoolers = lazy(() => import('./pages/WaterCoolers'))
const OurGuarantee = lazy(() => import('./pages/OurGuarantee'))
const Contact = lazy(() => import('./pages/Contact'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const Banner = lazy(() => import('./components/Layouts/Banner'))

const MainRoutes = () => {
  const location = useLocation()
  const path = useGetCurrentURLPath()

  return (
    <>
      {path !== '' && <Banner />}
      <Routes location={location} key={location.pathname}>
        <Route path={'/'} element={<Home />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/water-coolers'} element={<WaterCoolers />} />
        <Route path={'/our-guarantee'} element={<OurGuarantee />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
        <Route path={'/*'} element={<Navigate replace to="/" />} />
      </Routes>
    </>
  )
}

export default MainRoutes
