import { useLayoutEffect, useRef, useState, useTransition } from 'react'

import { BrowserHistory, createBrowserHistory, Update } from 'history'
import { Router } from 'react-router-dom'

export interface BrowserRouterProps {
  basename?: string
  children?: React.ReactNode
  window?: Window
}

const SuspenseRouter = ({ basename, children, window }: BrowserRouterProps) => {
  let historyRef = useRef<BrowserHistory>()
  const [isPending, startTransition] = useTransition()

  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window })
  }

  const history = historyRef.current
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  function setStateAsync(update: Update) {
    startTransition(() => {
      setState(update)
    })
  }

  useLayoutEffect(() => history.listen(setStateAsync), [history])

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  )
}
export default SuspenseRouter
