import { useCallback, useLayoutEffect } from 'react'

import { useScreenSizeContext } from 'contexts'

export const useResize = () => {
  const { setScreenWidth } = useScreenSizeContext()

  const handleResize = useCallback(() => {
    setScreenWidth(window.innerWidth)
  }, [setScreenWidth])

  useLayoutEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
}
